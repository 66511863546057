<template>
  <div class="services_section">
    <div class="cover_image_wrapper">
      <h2 class="cover_title">{{ currentLocale == 'bn' ? headerText.text_bn : headerText.text_en }}</h2>
      <img src="../../../../assets/images/portal/cover-img.jpg" alt="" />
    </div>
    <div class="breadcrumb_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/portal/home">{{ $t('portal.home') }}</router-link></li>
                <li class="breadcrumb-item"><router-link to="/portal/all-services">{{ $t('portal.all_service') }}</router-link></li>
                <li class="breadcrumb-item"><a href="javascript:" @click="$router.go(-1)">{{ $t('portal.service_details') }}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t('portal.request_venue_booking') }}</li>
              </ol>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="service_details_wrapper">
      <b-container>
          <b-row>
              <b-col md="12">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{$t('portal.request_venue_booking')}}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <b-button @click="pdfExport" :class="'btn btn_add_new mr-2'">
                        {{  $t('globalTrans.export_pdf') }}
                      </b-button>
                    </template>
                    <template v-slot:body>
                      <b-overlay :show="loading">
                        <b-row>
                          <b-col lg="12" sm="12" v-if="formData">
                            <div>
                              <p v-if="formData.org_id === 12">{{ $t('portal.sd_1') }}</p>
                              <p v-if="formData.org_id === 12" style="margin: 0px;padding: 0px;">{{ $t('portal.sd_2') }}</p>
                              <p v-if="formData.org_id === 12" style="margin: 0px;padding: 0px;">{{ $t('portal.sd_3') }}</p>
                              <p v-if="formData.org_id === 12">{{ $t('portal.sd_4') }}</p>
                              <p>{{ $t('portal.subject') + ' :' }} {{ ($i18n.locale === 'bn' ? formData.venu_name_bn : formData.venu_name) }} {{ $t('portal.service_application') }}</p>
                            </div>
                            <b-row>
                              <div class="w-100 text-left">
                                <div class="card-inside-title p-1">
                                  <h5 class="text-capitalize text-left p-2"
                                    style="color: white!important;font-size: 18px!important; background-color:#02813e!important;"> {{ $t('portal.applicant_information') }}</h5>
                                </div>
                              </div>
                            </b-row>
                            <div class="mt-2">
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.applicant_type') + ' : ' + getProfessionType(formData.gov_private) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.applicant_name') + ' : ' + ($i18n.locale === 'bn' ? formData.name_bn : formData.name) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('globalTrans.mobile') + ' : ' + ($i18n.locale === 'bn' ? '০' : '0') + $n(formData.mobile, { useGrouping: false }) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('globalTrans.email') + ' : ' + formData.email }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.name_institution') + ' : ' + ($i18n.locale === 'bn' ? formData.reservation_org_name_bn : formData.reservation_org_name) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.applicant_designation') + ' : ' + ($i18n.locale === 'bn' ? formData.applicant_designation_bn : formData.applicant_designation) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.address_individuals_institution') + ' : ' + ($i18n.locale === 'bn' ? formData.reservation_address_bn : formData.reservation_address) }}</p>
                            </div>
                            <b-row>
                              <div class="w-100 text-left">
                                <div class="card-inside-title p-1">
                                  <h5 class="text-capitalize text-left bg-light p-2 badge-light"
                                  style="color: white!important;font-size: 18px!important; background-color:#02813e!important;"> {{ $t('portal.venue_info') }}</h5>
                                </div>
                              </div>
                            </b-row>
                            <div class="mt-2">
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.venue_name') + ' : ' + ($i18n.locale === 'bn' ? formData.venu_name_bn : formData.venu_name) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('elearning_config.total_capacity') + ' : ' + $n(formData.total_capacity) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('elearning_venue.rent_amount') + ' : ' + $n(formData.rent_amount) }}</p>
                              <!-- <p style="margin: 2px;padding: 2px;">{{ $t('portal.total_amount') + ' : ' + $n(formData.total_amount) }}</p> -->
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.grant_total_amount') + ' : ' + $n(formData.include_total_amount) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('elearning_venue.male_participent') + ' : ' + $n(formData.male_participent) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('elearning_venue.female_participent') + ' : ' + $n(formData.female_participent) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('elearning_venue.total_participent') + ' : ' + $n(formData.total_participent) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.use_purpose') + ' : ' + ($i18n.locale === 'bn' ? formData.reason_bn : formData.reason) }}</p>
                              <p style="margin: 2px;padding: 2px;"><slot>{{ $t('portal.using_start_date') + ' : ' }}</slot> {{ formData.start_date | dateFormat }}</p>
                              <p style="margin: 2px;padding: 2px;"><slot>{{ $t('portal.using_end_date') + ' : ' }}</slot> {{ formData.end_date | dateFormat }}</p>
                              <p style="margin: 2px;padding: 2px;" v-if="formData.org_id === 4"><slot>{{ $t('portal.slot') + ' : ' }}</slot> {{ getSlot(formData.slot_id) }}</p>
                            </div>
                            <b-row>
                              <b-col md="4"></b-col>
                              <b-col md="4"></b-col>
                              <b-col md="4">
                                <p style="margin-bottom: 0px;padding-bottom: 0px; padding-top: 30px;" class="text-center">{{ $t('portal.sd_6') }}</p>
                                <p style="margin: 0px;padding: 0px;" class="text-center">{{ $i18n.locale === 'bn' ? formData.name_bn : formData.name }}</p>
                                <p style="margin: 0px;padding: 0px;" class="text-center">{{ $i18n.locale === 'bn' ? formData.applicant_designation_bn : formData.applicant_designation }}</p>
                                <p style="margin: 0px;padding: 0px;" class="text-center">{{ $i18n.locale === 'bn' ? formData.reservation_address_bn : formData.reservation_address }}</p>
                                <p style="margin: 0px;padding: 0px;" class="text-center">{{ formData.created_at | dateFormat }}</p>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-overlay>
                    </template>
                  </iq-card>
              </b-col>
              <pre>{{formData.org_id}}</pre>
          </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import ExportPdf from './export_pdf_Sponsored_training_details'
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
import flatpickr from 'flatpickr'

export default {
    mixins: [ModalBaseMasterList],
    components: {
    },
    data () {
      return {
        headerText: { text_en: '', text_bn: '' },
        loading: false,
        formData: {}
      }
    },
    watch: {
    },
    computed: {
      slotList: function () {
          return [
              { value: 1, text: this.$i18n.locale === 'en' ? '9.00 - 1.00' : '৯.০০ - ১.০০', text_en: '9.00 - 1.00', text_bn: '৯.০০ - ১.০০' },
              { value: 2, text: this.$i18n.locale === 'en' ? '2.00 - 5.00' : '২.০০ -৫. ০০', text_en: '2.00 - 5.00', text_bn: '০০ -৫. ০০' },
              { value: 3, text: this.$i18n.locale === 'en' ? 'Full Day' : 'সারা দিন', text_en: 'Full Day', text_bn: 'সারা দিন' }
          ]
      },
      professionTypeList () {
        const professionTypeList = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Govt' : 'সরকারী', text_en: 'Govt', text_bn: 'সরকারী' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Private' : 'বেসরকারী', text_en: 'Private', text_bn: 'বেসরকারী' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Person' : 'ব্যক্তি', text_en: 'Person', text_bn: 'ব্যক্তি' }
        ]
        return professionTypeList
      },
      officerTypeList: function () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Senior Officer' : 'ঊর্ধ্বতন কর্মকর্তা', text_en: 'Senior Officer', text_bn: 'ঊর্ধ্বতন কর্মকর্তা' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Mid-level Officer' : 'মিড লেভেল কর্মকর্তা', text_en: 'Mid-level Officer', text_bn: 'মিড লেভেল কর্মকর্তা' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Junior Officer' : 'জুনিয়র কর্মকর্তা', text_en: 'Junior Officer', text_bn: 'জুনিয়র কর্মকর্তা' }
        ]
      },
      ...mapGetters({
        authUser: 'Auth/authUser'
      }),
      currentLocale () {
        return this.$i18n.locale
      },
      gradeList: function () {
        return this.$store.state.Portal.commonObj.gradeList.filter(item => item.status === 0)
      }
    },
    async created () {
      this.loadData()
    },
    mounted () {
      core.index()
      flatpickr('.fromDate', {})
    },
    methods: {
      getProfessionType (id) {
        const trainingType = this.professionTypeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
            return trainingType !== undefined ? trainingType.text_en : ''
        }
      },
      getSlot (id) {
        const slotType = this.slotList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return slotType !== undefined ? slotType.text_bn : ''
        } else {
            return slotType !== undefined ? slotType.text_en : ''
        }
      },
      getGradeForm (id) {
        const trainingType = this.$store.state.Portal.commonObj.gradeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
            return trainingType !== undefined ? trainingType.text_en : ''
        }
      },
      getGradeTo (id) {
        const trainingType = this.$store.state.Portal.commonObj.gradeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
            return trainingType !== undefined ? trainingType.text_en : ''
        }
      },
      getOfficerType (id) {
        const trainingType = this.officerTypeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
            return trainingType !== undefined ? trainingType.text_en : ''
        }
      },
      async loadData () {
        this.loading = true
        const params = {
          id: this.$route.query.id
        }
        RestApi.getData(trainingElearningServiceBaseUrl, '/external-user/venue-reservation/show', params).then(response => {
          if (response.success) {
            this.formData = response.data
            this.loading = false
          } else {
            this.loading = false
          }
        })
        this.loading = false
      },
      pdfExport () {
        const reportTitle = this.$t('portal.request_venue_booking')
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', this.formData.org_id, reportTitle, this.formData, this)
      }
    }
}
</script>
<style>
  .alert .close {
    color: red !important;
  }
  #form fieldset {
    border: 1px solid #8f7c7c!important;
    float: left!important;
    margin-bottom: 25px!important;
    padding: 10px 0!important;
    width: 99.5%!important;
  }
  #form legend {
    background: #02813e none repeat scroll 0 0!important;
    border: 1px solid #e5e5e5!important;
    color: #fff!important;
    font-size: 13px!important;
    font-weight: 700!important;
    margin-bottom: 0!important;
    margin-left: 16px!important;
    padding: 6px 10px!important;
    width: 31%!important;
    border-radius: 0!important;
  }
  .hidden_header {
    display: none
  }

  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }

  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }

  .reportTitle {
    font-weight: bold;
    border: 1px solid;
    padding: 8px 15px 8px 15px;
    border-radius: 11px;
  }

  .titleDiv {
    margin-top: 2rem;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .report-name {
    font-weight: bold !important;
    text-transform: uppercase;
  }

  .my-btn {
    padding: 2px !important;
  }
</style>
